import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { dispatch } from "../../redux";
import { GeneralSearch } from "../../redux/action";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ReactComponent as DashFilter1 } from "../../assets/img/dash-filter-1.svg";
import { ReactComponent as DashFilter2 } from "../../assets/img/dash-filter-2.svg";
import { ReactComponent as DashFilter3 } from "../../assets/img/dash-filter-3.svg";
import { ReactComponent as DashFilter4 } from "../../assets/img/dash-filter-4.svg";
import { ReactComponent as DashFilter6 } from "../../assets/img/dash-filter-6.svg";

export const AutoSearch = () => {
  const [filterData, setFilterData] = useState([]);
  const history = useHistory()
  const GSearch = useSelector((state) => state?.shipmentReducer?.grlSearch);

  useEffect(() => {
    setFilterData(GSearch)
  }, [GSearch])

  const handleOnSearch = (string) => {
    dispatch(GeneralSearch(string))
  }

  const handleOnSelect = (item) => {
    if (item) {
      history.push({ pathname: "/seafright/" + item?.id })
      // switch (item?.transportMode) {
      //   case "SEA":
      //     history.push({ pathname: "/seafright/" + item?.id })
      //     break;
      //   case "AIR":
      //     history.push({ pathname: "/airfright/" + item?.id })
      //     break;
      //   case "LAND":
      //     history.push({ pathname: "/land-transport/" + item?.id })
      //     break;
      //   case "COUR":
      //     history.push({ pathname: "/courier/" + item?.id })
      //     break;
      //   default:
      //     history.push('/home')
      // }
    }
  }

  const formatResult = (item) => {
    return (
      <React.Fragment key={item.id}>
        <div className="dataResult primary-stroke cus-line" >
          {(item?.transportMode === "SEA") ? <DashFilter1 className="w-60 cus-icon" /> : (item?.transportMode === "AIR") ? <DashFilter2 className="w-60 cus-icon" /> : (item?.transportMode === "LAND") ? <DashFilter3 className="w-60 cus-icon" /> : (item?.transportMode === "COUR") ? <DashFilter4 className="w-60 cus-icon" /> : <DashFilter6 className="w-60 cus-icon" />}
          {" "}
          <a className="dataItem" href={item.link} target="_blank">
            <p className='cus-p'> <span className='cus-bold'>{item.name} </span>{" "} {item.origin} {" - "} {" "}{item.destination} </p>
          </a>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      <ReactSearchAutocomplete
        items={filterData}
        maxResults={30}
        onSearch={handleOnSearch}
        onSelect={handleOnSelect}
        fuseOptions={{ keys: ["name", "origin"] }}
        styling={{ zIndex: 3, height: "65px", border: "none", boxShadow: "none" }}
        formatResult={formatResult}
      />
    </div>
  )

}