import {apiCall, METHOD} from "../../service/baseApiCall";
import {
    LOGIN,
    PROFILEIMG,
    LOGOIMG,
    USER,
    LOGOUT,
    COMPANYPROFILE,
    COMPANYLIST,
    USERSDETAILS,
    CREATEUSERS,
    UPDATEUSERS,
    ORGANIZATIONDROPDOWN,
    SENDINVITATION,
    REGISTER_USER,
    DELETE_ORG_USER,
    MODIFY_USER,
    COMPANY_PHNO,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    NOTIFICATION_UPLOAD,
    NOTIFICATION_DETAILS, COMPANYUSERS, UPDATECOMPANY
} from "../constants/action-types";

import {setToken} from "../../utils";

import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import axios from '../../service/axiosConfig';
import {useHistory} from "react-router-dom";

export const login = (email, password) => (dispatch) => {
    dispatch(loginInit(email, password));
};

export const loginInit = (email, password) => (dispatch) => {
    dispatch({
        type: LOGIN.LOGIN_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + "login",
        {
            user: email,
            password: password
        },
        (res) => dispatch(loginSuccess(res)),
        () => dispatch(loginError()),
        METHOD.POST,
        {}
    );
};

export const loginSuccess = (res) => (dispatch) => {
    setToken("login-auth-token", JSON.stringify(res?.data?.token));
    setToken("isLoggedIn", 'true');
    dispatch({
        type: LOGIN.LOGIN_SUCCESS,
    });
    dispatch(fetchUserProfile());
    toast.dismiss();
    toast.success("login successfully")
};

export const loginError = () => (dispatch) => {
    dispatch({
        type: LOGIN.LOGIN_ERORR
    });
    toast.dismiss();
    toast.error('Please check your username or password')
};

//logout
export const logout = (showToast = true) => (dispatch) => {
    dispatch(logoutInit(showToast));
};

export const logoutInit = (showToast = true) => (dispatch) => {
    dispatch({
        type: LOGOUT.LOGOUT_INITLIZATION
    });
    dispatch(logoutSuccess(showToast));
};

export const logoutSuccess = (showToast = true) => (dispatch) => {
    localStorage.clear();
    if (showToast) {
        toast.dismiss();
        toast.success("logout successfully")
    }
    dispatch({
        type: LOGOUT.LOGOUT_SUCCESS,
        payload: {}
    });
    // window.location.reload();
};

export const logoutError = () => (dispatch) => {
    dispatch({
        type: LOGOUT.LOGOUT_ERORR
    });
};

//company User profile 

export const fetchUserProfile = () => (dispatch) => {
    dispatch(userProfileInit());
};

export const userProfileInit = () => (dispatch) => {
    dispatch({
        type: USER.USER_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + "settings/myprofile",
        {},
        (res) => dispatch(userProfileSuccess(res)),
        () => dispatch(userProfileError()),
        METHOD.GET,
        {}
    );
};

export const userProfileSuccess = (res) => (dispatch) => {
    dispatch({
        type: USER.USER_SUCCESS,
        payload: res?.data
    });
    let currentLanguage = res.data.language;
    const {i18n} = useTranslation();
    i18n.changeLanguage(currentLanguage);
};

export const userProfileError = () => (dispatch) => {
    dispatch({
        type: USER.USER_ERORR
    });
};


//Company Profile
export const fetchCompanyProfile = () => (dispatch) => {
    dispatch(companyProfileInit());
};

export const companyProfileInit = () => (dispatch) => {
    dispatch({
        type: COMPANYPROFILE.COMPANYPROFILE_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + "settings/mycompany",
        {},
        (res) => dispatch(companyProfileSuccess(res)),
        () => dispatch(companyProfileError()),
        METHOD.GET,
        {}
    );
};

export const companyProfileSuccess = (res) => (dispatch) => {
    dispatch({
        type: COMPANYPROFILE.COMPANYPROFILE_SUCCESS,
        payload: res?.data
    });
};

export const companyProfileError = () => (dispatch) => {
    dispatch({
        type: COMPANYPROFILE.COMPANYPROFILE_ERORR
    });
};

//Company List
export const companyList = () => (dispatch) => {
    dispatch(companyListInit());
};

export const companyListInit = () => (dispatch) => {
    dispatch({
        type: COMPANYLIST.COMPANYLIST_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + "settings/companieslist",
        {},
        (res) => dispatch(companyListSuccess(res)),
        () => dispatch(companyListError()),
        METHOD.GET,
        {}
    );
};

export const companyListSuccess = (res) => (dispatch) => {
    dispatch({
        type: COMPANYLIST.COMPANYLIST_SUCCESS,
        payload: res?.data
    });
};

export const companyListError = () => (dispatch) => {
    dispatch({
        type: COMPANYLIST.COMPANYLIST_ERORR
    });
};

//redux state Manage
export const profileImg = (img) => (dispatch) => {
    dispatch({
        type: PROFILEIMG.PROFILEIMG_UPLOAD,
        payload: img
    });
}

export const uploadLogoCompany = (companyLogo, companyId) => (dispatch) => {
    dispatch({
        type: LOGOIMG.LOGOIMG_UPLOAD_SUCCESS,
        payload: companyLogo
    });

    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/uploadLogo`,
        {
            "companyLogo": companyLogo
        },
        (res) => dispatch(uploadLogoSuccess(res)),
        (err) => dispatch(uploadLogoError(err)),
        METHOD.POST,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Content-Accept": "multipart/form-data"
            },

        }
    );
}

export const uploadLogoSuccess = (res) => (dispatch) => {
    dispatch({
        type: CREATEUSERS.CREATEUSERS_SUCCESS,
        // payload: res?.data
    });
};
export const uploadLogoError = (err) => (dispatch) => {
    dispatch({
        type: CREATEUSERS.CREATEUSERS_ERORR
    });
    console.log('ERR', err);
};

export const LogoImg = (img) => (dispatch) => {
    dispatch({
        type: LOGOIMG.LOGOIMG_UPLOAD,
        payload: img
    });

    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/uploadLogo`,
        {},
        (res) => dispatch(CreateUsersSuccess(res)),
        () => dispatch(CreateUsersError()),
        METHOD.POST,
        {}
    );
}

//create Users
export const CreateUsers = () => (dispatch) => {
    dispatch(CreateUsersInit());
};

export const CreateUsersInit = () => (dispatch) => {
    dispatch({
        type: CREATEUSERS.CREATEUSERS_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `users/new`,
        {},
        (res) => dispatch(CreateUsersSuccess(res)),
        () => dispatch(CreateUsersError()),
        METHOD.POST,
        {}
    );
};

export const CreateUsersSuccess = (res) => (dispatch) => {
    dispatch({
        type: CREATEUSERS.CREATEUSERS_SUCCESS,
        // payload: res?.data
    });
};
export const CreateUsersError = () => (dispatch) => {
    dispatch({
        type: CREATEUSERS.CREATEUSERS_ERORR
    });
};
//Update Users
export const UpdateUsers = () => (dispatch) => {
    dispatch(UpdateUsersInit());
};

export const UpdateUsersInit = () => (dispatch) => {
    dispatch({
        type: UPDATEUSERS.UPDATEUSERS_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `users/modify`,
        {},
        (res) => dispatch(UpdateUsersSuccess(res)),
        () => dispatch(UpdateUsersError()),
        METHOD.POST,
        {}
    );
};

export const UpdateUsersSuccess = (res) => (dispatch) => {
    dispatch({
        type: UPDATEUSERS.UPDATEUSERS_SUCCESS,
        // payload: res?.data
    });
};
export const UpdateUsersError = () => (dispatch) => {
    dispatch({
        type: UPDATEUSERS.UPDATEUSERS_ERORR
    });
}
//User Details by id
export const UsersDetails = () => (dispatch) => {
    dispatch(UsersDetailsInit());
};

export const UsersDetailsInit = () => (dispatch) => {
    dispatch({
        type: COMPANYUSERS.COMPANYUSERS_INITIALIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/user/1`,
        {},
        (res) => dispatch(UsersDetailsSuccess(res)),
        () => dispatch(UsersDetailsError()),
        METHOD.GET,
        {}
    );
};

export const UsersDetailsSuccess = (res) => (dispatch) => {
    dispatch({
        type: USERSDETAILS.USERSDETAILS_SUCCESS,
        payload: res?.data
    });
};

export const UsersDetailsError = () => (dispatch) => {
    dispatch({
        type: USERSDETAILS.USERSDETAILS_ERORR
    });
};
//company users
export const companyUsers = (cId) => (dispatch) => {
    dispatch(companyUsersInit(cId));
};

export const companyUsersInit = (cId) => (dispatch) => {
    dispatch({
        type: COMPANYUSERS.COMPANYUSERS_INITIALIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/userlist/${cId}`,
        {},
        (res) => dispatch(companyUsersSuccess(res)),
        () => dispatch(companyUsersError()),
        METHOD.GET,
        {}
    );
};

export const companyUsersSuccess = (res) => (dispatch) => {
    dispatch({
        type: COMPANYUSERS.COMPANYUSERS_SUCCESS,
        payload: res?.data
    });
};

export const companyUsersError = () => (dispatch) => {
    dispatch({
        type: COMPANYUSERS.COMPANYUSERS_ERORR
    });
};

export const updateCompany = (companyData) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATECOMPANY.UPDATECOMPANY_INITIALITATION
        });
        console.log('COMPANY DATA', companyData);
        const httpResponse = await axios
            .post(
                process.env.REACT_APP_BASE_API_CALL + `settings/modifycompany`,
                {
                    "id": companyData.id,
                    "colorCode": companyData.colorCode,
                    "timeZone": companyData.timeZone
                });
        console.log('POST DONE');
        console.log('httpResponse', httpResponse?.data);
        console.log('Trigering dispatch modify user success');
        dispatch({
            type: UPDATECOMPANY.UPDATECOMPANY_SUCCESS,
            payload: httpResponse?.data
        });
        toast.dismiss();
        toast.success("Company Modified Successfuly")
    } catch (err) {
        console.log('ERR', err);
        dispatch({
            type: UPDATECOMPANY.UPDATECOMPANY_ERROR
        });
        toast.dismiss();
        toast.error("Error in Modification")
    }


    //return await dispatch(modifyUserInit(id, firstName, lastName, email, dataFormat, language, phone));
};

export const companyUpdateSuccess = (res) => (dispatch) => {
    dispatch({
        type: UPDATECOMPANY.UPDATECOMPANY_INITIALITATION,
        payload: res?.data
    });
};

export const companyUpdateError = () => (dispatch) => {
    dispatch({
        type: UPDATECOMPANY.UPDATECOMPANY_ERROR
    });
};

//add organization
export const organizationDropdown = (text) => (dispatch) => {
    dispatch(organizationDropdownInit(text));
};

export const organizationDropdownInit = (text) => (dispatch) => {
    dispatch({
        type: ORGANIZATIONDROPDOWN.ORGANIZATIONDROPDOWN_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/findorganitzations?name=${text} `,
        {},
        (res) => dispatch(organizationDropdownSuccess(res)),
        () => dispatch(organizationDropdownError()),
        METHOD.GET,
        {}
    );
};

export const organizationDropdownSuccess = (res) => (dispatch) => {
    dispatch({
        type: ORGANIZATIONDROPDOWN.ORGANIZATIONDROPDOWN_SUCCESS,
        payload: res?.data
    });
};

export const organizationDropdownError = () => (dispatch) => {
    dispatch({
        type: ORGANIZATIONDROPDOWN.ORGANIZATIONDROPDOWN_ERORR
    });
};
//sendInvitation
export const sendInvitation = (id, email) => (dispatch) => {
    dispatch(sendInvitationInit(id, email));
};

export const sendInvitationInit = (id, email) => (dispatch) => {
    dispatch({
        type: SENDINVITATION.SENDINVITATION_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/sendinvitation`,
        {
            "email": email,
            "organizationId": id
        },
        (res) => dispatch(sendInvitationSuccess(res, id)),
        (err) => dispatch(sendInvitationError(err)),
        METHOD.POST,
        {}
    );
};

export const sendInvitationSuccess = (res, id) => (dispatch) => {
    dispatch({
        type: SENDINVITATION.SENDINVITATION_SUCCESS,
        payload: res?.data
    });
    dispatch(companyUsers(id))
    toast.dismiss();
    toast.success("Invitation Send Successfully.")
};

export const sendInvitationError = (err) => (dispatch) => {
    dispatch({
        type: SENDINVITATION.SENDINVITATION_ERORR
    });
    toast.dismiss();
    toast.error(err?.message)
};

//registration
export const registration = (userFname, userLname, password, email, userPhone, DateF, lang, securityCode) => (dispatch) => {
    dispatch(registrationInit(userFname, userLname, password, email, userPhone, DateF, lang, securityCode));
};

export const registrationInit = (userFname, userLname, password, email, userPhone, DateF, lang, securityCode) => (dispatch) => {
    dispatch({
        type: REGISTER_USER.REGISTER_USER_INITLIZATION
    });
    console.log('SECURITY CODE SEC', securityCode);
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/saveuser`,
        {
            "firstName": userFname,
            "lastName": userLname,
            "email": email,
            "password": password,
            "phone": userPhone,
            "dataFormat": DateF,
            "language": lang,
            "securityCode": securityCode,
        },
        (res) => dispatch(registrationSuccess(res)),
        () => dispatch(registrationError()),
        METHOD.POST,
        {}
    );
};

export const registrationSuccess = (res) => (dispatch) => {
    dispatch({
        type: REGISTER_USER.REGISTER_USER_SUCCESS,
        payload: res?.data
    });
};

export const registrationError = () => (dispatch) => {
    dispatch({
        type: REGISTER_USER.REGISTER_USER_ERORR
    });
};
//Modifier

export const modifyUser = (id, firstName, lastName, email, dataFormat, language, phone) => async (dispatch) => {
    try {
        console.log('INIT MODIFYING USER');
        dispatch({type: MODIFY_USER.MODIFY_USER_INITLIZATION});
        const httpResponse = await axios
            .post(
                process.env.REACT_APP_BASE_API_CALL + `settings/modifyuser`,
                {
                    "userId": id,
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "dataFormat": dataFormat,
                    "language": language,
                    "phone": phone
                });
        console.log('POST DONE');
        console.log('httpResponse', httpResponse?.data);
        console.log('Trigering dispatch modify user success');
        dispatch({
            type: MODIFY_USER.MODIFY_USER_SUCCESS,
            payload: httpResponse?.data
        });
        toast.dismiss();
        toast.success("User Modified Successfuly")
    } catch (err) {
        console.log('ERR', err);
        dispatch({
            type: MODIFY_USER.MODIFY_USER_ERROR
        });
        toast.dismiss();
        toast.error("Error in Modification")
    }


    //return await dispatch(modifyUserInit(id, firstName, lastName, email, dataFormat, language, phone));
};

export const modifyUserInit = (id, firstName, lastName, email, dataFormat, language, phone) => async (dispatch) => {
    dispatch({type: MODIFY_USER.MODIFY_USER_INITLIZATION});
    await apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/modifyuser`,
        {
            "userId": id,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "dataFormat": dataFormat,
            "language": language,
            "phone": phone
        },
        (res) => dispatch(modifyUserSuccess(res)),
        () => dispatch(modifyUserError()),
        METHOD.POST,
        {}
    );
};

export const modifyUserSuccess = (res) => async (dispatch) => {
    console.log('RES', res);
    dispatch({
        type: MODIFY_USER.MODIFY_USER_SUCCESS,
        payload: res?.data
    });
    toast.dismiss();
    toast.success("User Modified Successfuly")
};

export const modifyUserError = () => (dispatch) => {
    dispatch({
        type: MODIFY_USER.MODIFY_USER_ERROR
    });
    toast.dismiss();
    toast.error("Error in Modification")
};

//Delete User From User Organizations
export const deleteOrgUser = (user_id, cmp_id) => (dispatch) => {
    dispatch(deleteOrgUserInit(user_id, cmp_id));
};


export const deleteOrgUserInit = (user_id, cmp_id) => (dispatch) => {
    dispatch({
        type: DELETE_ORG_USER.DELETE_ORG_USER_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `settings/deleteuser/${user_id}`,
        {},
        (res) => dispatch(deleteOrgUserSuccess(res, cmp_id)),
        (err) => dispatch(deleteOrgUserError()),
        METHOD.DELETE,
        {}
    );
};

export const deleteOrgUserSuccess = (res, cmp_id) => (dispatch) => {
    dispatch({
        type: DELETE_ORG_USER.DELETE_ORG_USER_SUCCESS
    });

    dispatch(companyUsers(cmp_id))
    toast.dismiss();
    toast.success("User deleted Successfully.")
};

export const deleteOrgUserError = (err) => (dispatch) => {
    dispatch({
        type: DELETE_ORG_USER.DELETE_ORG_USER_ERORR
    });
    toast.dismiss();
    toast.success(err.message)
};

//get Company phone number

export const companyPhoneNumber = () => (dispatch) => {
    dispatch(companyPhoneNumberInit());
};

export const companyPhoneNumberInit = () => (dispatch) => {
    dispatch({
        type: COMPANY_PHNO.COMPANY_PHNO_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + "settings/companyphone",
        {},
        (res) => dispatch(companyPhoneNumberSuccess(res)),
        () => dispatch(companyPhoneNumberError()),
        METHOD.GET,
        {}
    );
};

export const companyPhoneNumberSuccess = (res) => (dispatch) => {
    dispatch({
        type: COMPANY_PHNO.COMPANY_PHNO_SUCCESS,
        payload: res?.data
    });
};

export const companyPhoneNumberError = () => (dispatch) => {
    dispatch({
        type: COMPANY_PHNO.COMPANY_PHNO_ERORR
    });
};

//forgot password


export const forgotPassword = (email) => (dispatch) => {
    console.log(email);
    dispatch(forgotPasswordInit(email));
};

export const forgotPasswordInit = (email) => (dispatch) => {
    dispatch({
        type: FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION
    });
    console.log('API ' + process.env.REACT_APP_BASE_API_CALL + `recovery`);
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `recovery`,
        {
            "user": email
        },
        (res) => dispatch(forgotPasswordSuccess(res)),
        (err) => dispatch(forgotPasswordError(err)),
        METHOD.POST,
        {}
    );
};

export const forgotPasswordSuccess = (res) => (dispatch) => {
    dispatch({
        type: FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS
    });
    toast.dismiss();
    toast.success("Mail Sent")
};

export const forgotPasswordError = (err) => (dispatch) => {
    dispatch({
        type: FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR
    });
    console.log(err);
    toast.dismiss();
    toast.error(err)
};
//Reset Password

export const resetPassword = (pw, sc) => (dispatch) => {
    dispatch(resetPasswordInit(pw, sc));
};

export const resetPasswordInit = (pw, sc) => (dispatch) => {
    dispatch({
        type: RESET_PASSWORD.RESET_PASSWORD_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `updatepassword`,
        {
            "user": sc,
            "password": pw
        },
        (res) => dispatch(resetPasswordSuccess(res)),
        (err) => dispatch(resetPasswordError(err)),
        METHOD.POST,
        {}
    );
};

export const resetPasswordSuccess = (res) => (dispatch) => {
    dispatch({
        type: RESET_PASSWORD.RESET_PASSWORD_SUCCESS,
        payload: res?.data
    });
    toast.dismiss();
    toast.success("Reset Password Successfully")
    const {push} = useHistory();
    push("/");
};

export const resetPasswordError = (err) => (dispatch) => {
    dispatch({
        type: RESET_PASSWORD.RESET_PASSWORD_ERORR
    });
    toast.dismiss();
    toast.error(err?.message)
};

//Upload Notifications

export const notificationUpload = (id, shipmentId, reference, statusdata, responsable, creationDate, description, summary) => (dispatch) => {
    console.log('NOTIFICATIONS UPLOAD INIT');
    dispatch(notificationUploadInit(id, shipmentId, reference, statusdata, responsable, creationDate, description, summary));
    console.log('NOTIFICATIONS UPLOAD DONE');
};

export const notificationUploadInit = (id, shipmentId, reference, statusdata, responsable, creationDate, description, notification_id) => (dispatch) => {
    dispatch({
        type: NOTIFICATION_UPLOAD.NOTIFICATION_UPLOAD_INITLIZATION
    });
    console.log('values submit');
    console.log('ID', id);
    console.log('SHIPMENT ID', shipmentId);
    console.log('reference', reference);
    console.log('statusdata', statusdata);
    console.log('responsable', responsable);
    console.log('creationDate', creationDate);

    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `notification/upload`,
        {
            "id": id,
            "shipmentId": shipmentId,
            "reference": reference,
            "status": statusdata,
            "responsable": responsable,
            "creationDate": creationDate,
            "description": description
        },
        (res) => dispatch(notificationUploadSuccess(res)),
        (err) => dispatch(notificationUploadError(err)),
        METHOD.POST,
        {}
    );
};

export const notificationUploadSuccess = (res) => (dispatch) => {
    dispatch({
        type: NOTIFICATION_UPLOAD.NOTIFICATION_UPLOAD_SUCCESS,
        payload: res?.data
    });
    toast.dismiss();
    toast.success("NOTIFICATION UPLOAD Successfully")
};

export const notificationUploadError = (err) => (dispatch) => {
    dispatch({
        type: NOTIFICATION_UPLOAD.NOTIFICATION_UPLOAD_ERORR
    });
    toast.dismiss();
    toast.error(err?.message)
};

//Notification details

export const notificationDetails = (notification_id) => (dispatch) => {
    dispatch(notificationDetailsInit(notification_id));
};

export const notificationDetailsInit = (notification_id) => (dispatch) => {
    dispatch({
        type: NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `notification/${notification_id}`,
        {},
        (res) => dispatch(notificationDetailsSuccess(res)),
        (err) => dispatch(notificationDetailsError(err)),
        METHOD.GET,
        {}
    );
};

export const notificationDetailsSuccess = (res) => (dispatch) => {
    dispatch({
        type: NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SUCCESS,
        payload: res?.data
    });
};

export const notificationDetailsError = (err) => (dispatch) => {
    dispatch({
        type: NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_ERORR
    });
    toast.dismiss();
    toast.error(err?.message)
};




