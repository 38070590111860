import React, {useEffect, useState} from 'react';
import {Card, Button, ToggleButton, ToggleButtonGroup, Col} from 'react-bootstrap';
import {ReactComponent as Bell} from '../../assets/img/bell.svg';
import NeedHelpBox from '../need-help-box/need-help-box';
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import cookies from 'js-cookie';
import {UploadNotifications} from "../../modals/UploadNotifications";
import {useDispatch, useSelector} from "react-redux";
import {checkNotification, getNotificationsColumn} from "../../redux/action/notificationActions";

const NotificationLA = () => {
    const dispatch = useDispatch();
    const [id, setId] = useState();

    const [showM, setShowM] = useState(false);
    const [data, setData] = useState('false');
    const handleCallback = () => setShowM(false);
    const handleShow = () => setShowM(true);
    const history = useHistory()
    const notification = useSelector((state) => state?.shipmentReducer?.notificationColumnList);

    const upload = () => {
        document.getElementById("selectImage").click()
    }

    useEffect(() => {
        dispatch(getNotificationsColumn());
    }, [dispatch]);

    /*useEffect(() => {
        if (NotificationCmpData) {
            console.log('NOTICATION', NotificationCmpData);
            setNotification(NotificationCmpData)
        }
    }, [NotificationCmpData])*/

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const {t} = useTranslation()
    const handleRedirect = () => {
        history.push({pathname: "/notifications"})
    }
    useEffect(() => {
        document.body.dir = currentLanguageCode.dir || 'ltr'
    }, [currentLanguageCode, t])

    const handleNotification = (e) => {
        setData(e);
    }
    const handleFresh = () => {
        dispatch(getNotificationsColumn());
    }
    const handleShowNot = (id) => {
        setId(id);
        setShowM(true);
    }
    const handleCheck = (id) => {
        dispatch(checkNotification(id));
    }
    return (
        <>
            <Col xl={3} className='d-flex flex-column'>
                <Card>
                    <Card.Body>
                        <h3>{t('Latest_Activity')}
                            <span className="refresh-icon" onClick={handleFresh}>
                                {" "}<i className="zmdi zmdi-refresh font-size-18 align-middle"></i>
                            </span>
                        </h3>

                        {/*<ToggleButtonGroup type="radio" name="options" defaultValue='false'*/}
                        {/*                   className="dashboard-filter latest-activity" value={data}*/}
                        {/*                   onChange={(e) => handleNotification(e)}>*/}
                        {/*    <ToggleButton id="tbg-radio-1" variant="" value='false'>*/}
                        {/*        {t('All')}*/}
                        {/*    </ToggleButton>*/}
                        {/*    <ToggleButton id="tbg-radio-2" variant="" value='true'>*/}
                        {/*        <Bell/> {t('Require_your_Action')}*/}
                        {/*    </ToggleButton>*/}
                        {/*</ToggleButtonGroup>*/}

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        {notification &&
                            notification?.map((item, index) => (
                                <React.Fragment key={item?.id}>
                                    <div key={index}>
                                        {!item?.checked && (
                                            <div
                                                className="card card-body d-flex align-items-center flex-row
                                        flex-wrap p-3 justify-content-between left-border-box">
                                                <div className='content m-b-5'
                                                     onClick={() => {handleCheck(item.id)
                                                         history.push({pathname: "/seafright/" + item?.shipmentId})}}>
                                                    <p className='m-0'><small>{item.createdDate}</small>
                                                        {item.actionRequired === true ? <Bell/> : null}
                                                    </p>
                                                    <p className='mb-1'><strong>{item.reference}</strong></p>
                                                    <small>{item.description}</small>
                                                </div>
                                                {item.upload === true ?
                                                    <Button type="button" variant="primary" className='btn-sm m-t-5'
                                                            id='uploadBtn'
                                                            onClick={() => handleShowNot(item.id)}> {t('Upload')} </Button>
                                                    : null}

                                            </div>)}

                                        {item?.checked && (
                                            <div
                                                className="card card-body d-flex align-items-center flex-row
                                                flex-wrap p-3 justify-content-between">
                                                <div className='content m-b-5'
                                                     onClick={() =>  {history.push({pathname: "/seafright/" + item?.shipmentId})}}>
                                                    <p className='m-0'><small>{item.createdDate}</small>
                                                        {item.actionRequired === true ? <Bell/> : null}
                                                    </p>
                                                    <p className='mb-1'><strong>{item.reference}</strong></p>
                                                    <small>{item.description}</small>
                                                </div>
                                                {item.upload === true ?
                                                    <Button type="button" variant="primary" className='btn-sm m-t-5'
                                                            id='uploadBtn'
                                                            onClick={() => handleShowNot(item.id)}> {t('Upload')} </Button>
                                                    : null}

                                            </div>)}
                                    </div>
                                </React.Fragment>
                            ))}
                        <div className='text-right'>
                            {(notification?.length > 9) ?
                                <Button variant='link' className='px-0' onClick={handleRedirect}>More</Button> : null}

                        </div>
                    </Card.Body>
                </Card>
                <NeedHelpBox/>

            </Col>
            {(id) ? <UploadNotifications showData={showM} parentCallback={handleCallback} id={id}/> : null}
        </>

    )

}
export default NotificationLA;