import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Organization from "../../assets/img/organization.svg";
import { ReactComponent as SearchIcon } from '../../assets/img/search-icon.svg'
import { ChangePermission } from "../../components";
import PaginationComp from "../../components/pagination/PaginationComp";

import { dispatch } from "../../redux";
import { useSelector } from "react-redux";
import { companyList} from "../../redux/action";
import AddUser from "../../modals/AddUser";
import {useTranslation} from "react-i18next";
const UserOrganization = () => {

    const [sessionsPerPage, setSessionsPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    const [showOrg, setShowOrg] = useState("Show");
    const [showEmp, setShowEmp] = useState("hide");
    const [cList, setClist] = useState([]);
    const [cmpId, setCmpId] = useState('');

    const cListData = useSelector(
        (state) => state.userDataReducer.CompanyList
    );
    const allSessionsCount = cList?.length;
    const lastSessionNumber = currentPage * sessionsPerPage;
    const firstSessionIndex = lastSessionNumber - sessionsPerPage;
    const limitedSessions = cList?.slice(
        firstSessionIndex,
        lastSessionNumber
    );
    const { t } = useTranslation()

    const handleShow = () => {
        setShowOrg("hide")
        setShowEmp("Show")
    }
    const handleGoBack =()=>{

         setShowOrg("Show");
         setShowEmp("hide");
       
    }
    useEffect(() => {
        dispatch(companyList());
    }, [])
   
    useEffect(() => {
        if (cListData) {
            setClist(cListData);
        }
    }, [cListData]);

    return (
        <React.Fragment>
            <Card className={showOrg}>
                <Card.Body className="px-lg-4 pt-lg-4"  >
                    {/* <p className="font-size-15 text-black font-weight-bold mb-lg-4"> This service project es open to customers who added to the proyect. <Link className="text-primary-custom" to="/home"> Change permissions. </Link></p> */}
                    <Row className='mb-2 mb-lg-4 align-items-center'>
                        <Col sm={4} lg={3}>
                            <div className='table-top-search'>
                                <SearchIcon className='icon' />
                                <Form.Control type="text" placeholder={t('Search')} />
                            </div>
                        </Col>
                        <Col sm={8} className='ml-auto text-right mt-2 mt-sm-0'>
                            <AddUser showBtn="" orgName=" " orgId=""/>

                        </Col>
                    </Row>
                    <Table responsive borderless hover>
                        <thead>
                            <tr>
                                <th className="w-75 pr-0"></th>
                                <th className="pl-0">{t('Company')} </th>
                                <th className="text-center">{t('Ongoing_shipments')}  </th>
                                <th>{t('Users')} </th>
                                <th className="table-field-actions"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {limitedSessions &&
                                limitedSessions?.map((item) => (
                                    <tr key={item.companyId} onClick={() => setCmpId(item.companyId)}>
                                        <td className="pr-0" onClick={handleShow}><img src={Organization} alt="Organization" /></td>
                                        <td className="pl-0" onClick={handleShow}>{item.companyName}</td>
                                        <td className="text-center" onClick={handleShow}>{item.ongoingShipments}</td>
                                        <td onClick={handleShow}>{item.totalUsers} Users</td>
                                        <td className="table-field-actions actions-dropdown" >
                                            <span>
                                                {/* <img src={Add} alt="Add" className="mr-2" /> Add User */}
                                                <AddUser isChild={true} orgName={item.companyName} orgId={item.companyId} />
                                            </span>
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </Table>
                    <PaginationComp
                        itemsCount={allSessionsCount} //{allSessionsCount}
                        itemsPerPage={sessionsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        alwaysShown={false}
                    />
                </Card.Body>
            </Card>
            <ChangePermission Show={showEmp} cmpId={cmpId} handleGoBack={handleGoBack} />
        </React.Fragment>
    );
};

export default UserOrganization;
